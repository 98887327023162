import React, { useEffect, useState } from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import RuleGroup from "./RuleGroup";
import { RuleRowType } from "./RuleRow";
import { SegmentType } from "../../services/segments";
import { EntitiesEnum } from "../../services/queryBuilder";

type QueryBuilderProps = {
  segment?: SegmentType;
  entitiesToQueryBy: EntitiesEnum[];
  fromPurchases?: boolean;
  onUpdateResults: () => void;
  onQueryUpdate: (segment: SegmentType) => void;
};

// TODO: unify this with the one in RuleGroup.tsx
const blankRow: RuleRowType = {
  field: null,
  condition: "",
  value1: "",
};

const QueryBuilder = ({
  segment,
  entitiesToQueryBy,
  fromPurchases,
  onUpdateResults,
  onQueryUpdate,
}: QueryBuilderProps) => {
  const [segmentState, setSegmentState] = useState<SegmentType>({
    id: "",
    name: "",
    ruleGroups: [[blankRow]],
    entity_type: entitiesToQueryBy[0],
    not_purchased: 0
  });

  const handleAddRuleGroup = () => {
    setSegmentState({
      ...segmentState,
      ruleGroups: [...segmentState.ruleGroups, [blankRow]],
    });
  };

  const handleRemoveRuleGroup = (index: number) => {
    const newRuleGroups = [...segmentState.ruleGroups];
    newRuleGroups.splice(index, 1);

    if (newRuleGroups.length === 0) {
      newRuleGroups.push([
        {
          field: null,
          condition: "",
          value1: "",
        },
      ]);
    }

    const newState = {
      ...segmentState,
      ruleGroups: newRuleGroups,
    };
    setSegmentState(newState);
    onQueryUpdate(newState);
  };

  const handleUpdateRuleGroup = (ruleRows: RuleRowType[], index: number) => {
    const newRuleGroups = [...segmentState.ruleGroups];
    newRuleGroups[index] = ruleRows;

    const newState = {
      ...segmentState,
      ruleGroups: newRuleGroups,
    };
    setSegmentState(newState);
    onQueryUpdate(newState);
  };

  useEffect(() => {
    if (segment && segment.ruleGroups.length > 0) {
      setSegmentState(segment);
    }
  }, [segment]);

  return (
    <Box w={"100%"} mt={"10px"} mb={"32px"}>
      {segmentState.ruleGroups.map((ruleGroup, index) => (
        <Flex direction={"column"}>
          {index > 0 && (
            <Image
              width={"1px"}
              height={"20px"}
              backgroundColor={"#DFE2E6"}
              ml={"90px"}
            />
          )}
          <RuleGroup
            entitiesToQueryBy={entitiesToQueryBy}
            allowAndBtn={index === segmentState.ruleGroups.length - 1}
            key={index}
            fromPurchases={fromPurchases === true}
            ruleRows={ruleGroup}
            onAddRuleGroup={handleAddRuleGroup}
            onRemoveRuleGroup={() => handleRemoveRuleGroup(index)}
            onRuleGroupUpdate={(ruleRows) =>
              handleUpdateRuleGroup(ruleRows, index)
            }
            onUpdateResults={onUpdateResults}
          />
        </Flex>
      ))}
    </Box>
  );
};

export default QueryBuilder;
